<template>
  <CustomModal
    :name="modalName"
    :classes="['custom-field-modal']"
    @before-close="$emit('close')"
  >
    <div class="custom-field-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <span class="custom-field-modal__title" :class="isStandard && 'standard'">
        Fields Settings
      </span>
    </template>

    <div v-if="isStandard" class="custom-field-modal__tip">
      <Icon name="info" color="primary" />
      {{ defaultLabel }} field is strongly recommended for waiver form.
    </div>

    <Form
      class="custom-field-modal__form m-scrollable"
      v-slot="{ invalid, changed }"
    >
      <div class="custom-field-modal__formWrapper">
        <FormItem
          rules="required"
          :customError="!isValidKey ? 'Enter unique field key' : null"
          v-slot="{ isError }"
        >
          <Input
            v-model="innerValue.key"
            label="Key"
            placeholder="Insert key"
            :isError="isError"
            :is-disabled="isDefaultField"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="innerValue.label"
            label="Field text"
            :placeholder="defaultLabel"
            :isError="isError"
          />
        </FormItem>
        <FormItem>
          <Input v-model="innerValue.hint" label="Hint" placeholder="Hint" />
        </FormItem>
        <FormItem v-if="isFieldDate(value)">
          <Checkbox v-model="innerValue.isRestrictFutureDates">
            Restrict future dates
          </Checkbox>
        </FormItem>
        <FormItem v-if="isDeletable">
          <Checkbox v-model="innerValue.isRequired">Required</Checkbox>
        </FormItem>
        <template v-if="innerValue.options">
          <FormItem class="custom-field-modal__form__options">
            <div
              v-for="(_, index) in innerValue.options"
              :key="index"
              class="custom-field-modal__form__options__item"
            >
              <Input
                v-model="innerValue.options[index]"
                :label="index === 0 ? 'Options' : ''"
              />
              <Icon
                v-if="innerValue.options.length > 2"
                name="trash"
                color="secondary-400"
                is-clickable
                @click="removeOption(index)"
              />
            </div>
          </FormItem>
          <FormItem>
            <Checkbox v-model="innerValue.isAllowCustomOption">
              Allow custom option
            </Checkbox>
          </FormItem>
          <IconButton icon="plus" @click="addOption">Add option</IconButton>
        </template>
      </div>
      <div class="custom-field-modal__actions">
        <Button
          :is-block="['xs', 'sm'].includes($mq)"
          :isDisabled="invalid || !(changed || isOptionsChanged) || !isValidKey"
          @click="$emit('save', innerValue)"
        >
          Save
        </Button>
        <template v-if="isDeletable">
          <Button
            v-if="!value.isDisabled"
            variant="danger"
            :is-block="['xs', 'sm'].includes($mq)"
            isOutlined
            @click="$emit('remove')"
          >
            Delete field
          </Button>
          <Button
            v-else-if="isStandard"
            :is-block="['xs', 'sm'].includes($mq)"
            isOutlined
            @click="$emit('restore')"
          >
            Restore field
          </Button>
        </template>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import { StandardWaiverFieldsEnum, CustomFieldTypeEnum } from "@/helpers/enums";
import {
  NON_DELETABLE_STANDARD_WAIVER_FIELDS,
  STANDARD_WAIVER_FIELDS_LABELS_MAP,
} from "@/helpers/const";

export default {
  name: "CustomFieldModal",
  props: {
    modalName: {
      type: String,
      default: "customFieldModal",
    },
    value: {
      type: Object,
      required: true,
    },
    fieldKeys: {
      type: Array,
      default: () => [],
    },
    defaultFields: {
      type: Object,
    },
  },
  data() {
    return {
      innerValue: {},
    };
  },
  computed: {
    isDefaultField() {
      return (
        this.defaultFields &&
        !!Object.keys(this.defaultFields).find((item) => {
          return item === this?.innerValue?.key;
        })
      );
    },
    isValidKey() {
      return !Object.values(this.fieldKeys).includes(this?.innerValue?.key);
    },
    isStandard() {
      return Object.values(StandardWaiverFieldsEnum).includes(
        this.innerValue.key,
      );
    },
    isDeletable() {
      return !NON_DELETABLE_STANDARD_WAIVER_FIELDS.includes(
        this.innerValue.key,
      );
    },
    defaultLabel() {
      return STANDARD_WAIVER_FIELDS_LABELS_MAP[this.innerValue.key];
    },
    isOptionsChanged() {
      return (
        this.value.options &&
        (this.value.options.length !== this.innerValue.options.length ||
          this.value.options.some(
            (option, index) => option !== this.innerValue.options[index],
          ))
      );
    },
  },
  watch: {
    value(newVal) {
      const resultValue = { ...newVal };
      if (newVal.options) {
        resultValue.options = [...newVal.options];
      }
      this.innerValue = resultValue;
    },
  },
  methods: {
    addOption() {
      this.innerValue.options?.push(
        `Option ${this.innerValue.options.length + 1}`,
      );
    },
    isFieldDate(field) {
      return field.type === CustomFieldTypeEnum.DATE;
    },
    removeOption(index) {
      this.innerValue.options.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.custom-field-modal {
  position: relative;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 48px);

  @media (min-width: $media-laptop) {
    padding: 40px;
  }
}
</style>

<style lang="scss" scoped>
.custom-field-modal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;

    &.standard {
      margin-bottom: 24px;
    }
  }

  &__tip {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $secondary-200;
    color: $secondary-500;
    font-size: 12px;
    line-height: 20px;
    padding: 12px 16px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 48px;

    &__options {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__item {
        display: flex;
        align-items: center;
        gap: 24px;

        :first-child {
          flex: 1;
        }
      }
    }
  }

  &__formWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
