<template>
  <CustomModal
    :name="modalName"
    :classes="['custom-field-type-modal']"
    @before-close="reset"
  >
    <div class="custom-field-type-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <span class="custom-field-type-modal__title"> Add new field </span>
    </template>

    <div class="custom-field-type-modal__list">
      <div
        v-for="type in types"
        :key="type.value"
        class="custom-field-type-modal__list__item"
        :class="{ selected: selectedType === type.value }"
        @click="selectedType = type.value"
      >
        <Icon :name="getTypeIcon(type.value)" color="secondary-500" />
        {{ type.name }}
      </div>
    </div>

    <Button
      :is-block="['xs', 'sm'].includes($mq)"
      :isDisabled="!selectedType"
      @click="acceptSelection"
    >
      Add field
    </Button>
  </CustomModal>
</template>

<script>
import {
  CUSTOM_FIELD_TYPE_ICON_MAP,
  CUSTOM_FIELD_TYPE_OPTIONS,
} from "@/helpers/mocks";

export default {
  name: "CustomFieldTypeModal",
  props: {
    modalName: {
      type: String,
      default: "customFieldTypeModal",
    },
  },
  data() {
    return {
      selectedType: null,
      types: CUSTOM_FIELD_TYPE_OPTIONS,
    };
  },
  methods: {
    reset() {
      this.selectedType = null;
    },
    getTypeIcon(type) {
      return CUSTOM_FIELD_TYPE_ICON_MAP[type];
    },
    acceptSelection() {
      this.$emit("select", this.selectedType);
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style lang="scss">
.custom-field-type-modal {
  position: relative;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }
}
</style>

<style lang="scss" scoped>
.custom-field-type-modal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 42px;

    &__item {
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-500;
      cursor: pointer;
      background-color: $white;
      transition: 0.3s;
      border: 2px solid $secondary-400;
      border-radius: 8px;

      &:hover {
        background-color: $secondary-100;
      }

      &.selected {
        background-color: $secondary-200;
      }
    }
  }
}
</style>
