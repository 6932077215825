<template>
  <div class="custom-fields-controller">
    <div class="custom-fields-controller__header">
      {{ label }}
      <Icon
        v-if="Object.keys(fields).length > 1 && !isDisabled"
        name="rectangle"
        is-clickable
        :color="isDragAndDropActive ? 'primary' : 'secondary-500'"
        @click="isDragAndDropActive = !isDragAndDropActive"
      />
    </div>
    <Draggable
      v-model="sortedFields"
      :disabled="!isDragAndDropActive"
      handle=".drag-handler"
      class="custom-fields-controller__fields"
    >
      <div
        v-for="field in sortedFields"
        :key="field.key"
        class="custom-fields-controller__field"
      >
        <div class="action drag-handler" v-if="isDragAndDropActive">
          <Icon name="equal" color="secondary-500" />
        </div>
        <Input
          :value="field.label"
          :placeholder="getFieldPlaceholder(field)"
          :isDisabled="isDisabled || field.isDisabled"
          is-readonly
          :prepend-icon="getTypeIcon(field.type)"
          class="m-expand"
        />
        <div class="action" v-if="!isDisabled && !isDragAndDropActive">
          <Icon
            name="pencil"
            color="secondary-500"
            is-clickable
            @click="editField(field)"
          />
        </div>
      </div>
    </Draggable>
    <IconButton v-if="!isDisabled" icon="plus" @click="selectFieldType">
      Add field
    </IconButton>
    <CustomFieldModal
      v-if="!isDisabled"
      :modal-name="modalName"
      :value="editedField"
      :fieldKeys="
        sortedFields
          .map((field) => field.key)
          .filter((key) => key !== editedKey)
      "
      :defaultFields="defaultFields"
      @save="saveField"
      @close="resetEditedField"
      @remove="removeField"
      @restore="restoreField"
    />
    <CustomFieldTypeModal
      v-if="!isDisabled"
      :modal-name="typeModalName"
      @select="addField"
    />
  </div>
</template>

<script>
import { CustomFieldTypeEnum } from "@/helpers/enums";
import CustomFieldModal from "@/components/common/customFields/CustomFieldModal";
import CustomFieldTypeModal from "@/components/common/customFields/CustomFieldTypeModal";
import { StandardWaiverFieldsEnum } from "@/helpers/enums";
import { STANDARD_WAIVER_FIELDS_LABELS_MAP } from "@/helpers/const";
import {
  getNewWaiverFieldMock,
  CUSTOM_FIELD_TYPE_ICON_MAP,
} from "@/helpers/mocks";
import Draggable from "vuedraggable";

export default {
  name: "CustomFieldsController",
  components: { CustomFieldTypeModal, CustomFieldModal, Draggable },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: "Fields",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    defaultFields: {
      type: Object,
    },
  },
  data() {
    return {
      modalName: "customFieldModal",
      typeModalName: "customFieldTypeModal",
      editedField: {},
      isDragAndDropActive: false,
    };
  },
  computed: {
    fields: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    sortedFields: {
      get() {
        return Object.entries(this.fields)
          .map(([key, value]) => ({
            key,
            ...value,
          }))
          .sort((a, b) => a.sortWeight - b.sortWeight);
      },
      set(newValue) {
        const copy = { ...this.fields };
        newValue.forEach((item, index) => {
          copy[item.key].sortWeight = index * 10;
        });
        this.fields = copy;
      },
    },
    editedKey() {
      return this.editedField.key;
    },
  },
  methods: {
    getFieldPlaceholder(field) {
      const defaultLabel = STANDARD_WAIVER_FIELDS_LABELS_MAP[field.key];
      return defaultLabel !== field.label ? defaultLabel : "";
    },
    isFieldDate(field) {
      return field.type === CustomFieldTypeEnum.DATE;
    },
    editField(field) {
      this.editedField = field;
      this.$modal.show(this.modalName);
    },
    saveField(newFieldValue) {
      const copy = { ...this.fields };
      if (newFieldValue.key !== this.editedKey) {
        delete copy[this.editedKey];
      }
      copy[newFieldValue.key] = newFieldValue;

      this.fields = copy;
      this.closeModal();
    },
    removeField() {
      const copy = { ...this.fields };
      if (Object.values(StandardWaiverFieldsEnum).includes(this.editedKey)) {
        copy[this.editedKey].isDisabled = true;
      } else {
        delete copy[this.editedKey];
      }
      this.fields = copy;
      this.closeModal();
    },
    restoreField() {
      const copy = { ...this.fields };
      copy[this.editedKey].isDisabled = false;
      this.fields = copy;
      this.closeModal();
    },
    selectFieldType() {
      this.$modal.show(this.typeModalName);
    },
    addField(type) {
      const filedsLength = this?.fields ? Object.keys(this.fields).length : 0;

      const newFieldValue = getNewWaiverFieldMock({
        type,
        label: "Custom field",
        sortWeight: Object.keys(this.fields).length * 10,
        isRequired: false,
      });

      const newFieldKey = `field-${filedsLength + 1}`;

      this.fields = {
        ...this.fields,
        [newFieldKey]: newFieldValue,
      };

      this.editField({ key: newFieldKey, ...newFieldValue });
    },
    resetEditedField() {
      this.editedField = {};
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
    getTypeIcon(type) {
      return CUSTOM_FIELD_TYPE_ICON_MAP[type];
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-fields-controller {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__field {
    display: flex;
    align-items: stretch;
    gap: 24px;

    .action {
      display: flex;
      align-items: center;

      &.drag-handler {
        cursor: grab;
      }
    }

    .m-expand {
      flex: 1;
    }
  }
}
</style>
